/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import axios from "axios";

export default class History extends Component {
  state = {
    history: [],
  };

  async componentDidMount() {
    await this.refresh();
  }

  async refresh() {
    let res = await axios.get("https://owen.agolzer.com/api/history.php");
    this.setState({ history: res.data });
  }

  render() {
    return (
      <table border="1">
        <thead>
          <tr>
            <td>Created</td>
            <td>ID</td>
            <td>Points</td>
            <td>Reason</td>
          </tr>
        </thead>
        <tbody>
          {this.state.history.map((h, index) => (
            <tr>
              <td>{h.created}</td>
              <td>{h.id}</td>
              <td>{String(h.cents)}</td>
              <td>{h.reason}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
