/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Coin1h from "../../resources/img/coins/coins-usa-1h.jpg";
import Coin5h from "../../resources/img/coins/coins-usa-5h.jpg";
import Coin10h from "../../resources/img/coins/coins-usa-10h.jpg";
import Coin25h from "../../resources/img/coins/coins-usa-25h.jpg";
/*import Coin50h from "../../resources/img/coins/coins-usa-50h.jpg";
import Coin1t from "../../resources/img/coins/coins-usa-1t.jpg";
import Coin5t from "../../resources/img/coins/coins-usa-5t.jpg";
import Coin10t from "../../resources/img/coins/coins-usa-10t.jpg";
import Coin25t from "../../resources/img/coins/coins-usa-25t.jpg";
import Coin50t from "../../resources/img/coins/coins-usa-50t.jpg";
*/
export default class ImageButton extends Component {
  render() {
    let { cents, key } = this.props;
    let c1 = 0;
    let c5 = 0;
    let c10 = 0;
    let c25 = 0;
    c25 = Math.floor(cents / 25);
    cents = cents - c25 * 25;
    c10 = Math.floor(cents / 10);
    cents = cents - c10 * 10;
    c5 = Math.floor(cents / 5);
    cents = cents - c5 * 5;
    c1 = cents;
    let ret = [];
    for (let i = 0; i < c25; i++) {
      ret.push(this.renderCoin(Coin25h, key + "_" + i + "c25h"));
    }
    for (let i = 0; i < c10; i++) {
      ret.push(this.renderCoin(Coin10h, key + "_" + i + "c10h"));
    }
    for (let i = 0; i < c5; i++) {
      ret.push(this.renderCoin(Coin5h, key + "_" + i + "c5h"));
    }
    for (let i = 0; i < c1; i++) {
      ret.push(this.renderCoin(Coin1h, key + "_" + i + "c1h"));
    }
    return ret;
  }

  renderCoin(coin, key) {
    return (
      <img
        key={key}
        src={coin}
        alt=""
        style={{ width: "50px", height: "50px" }}
      />
    );
  }
}
