/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import axios from "axios";
import Coins from "./common/coins";
import Tasks from "./common/tasks";

function getUrlParts() {
  let ret = { pathParts: [], params: {} };
  let url = new URL(window.location);
  url.searchParams.forEach((value, key) => {
    ret.params[key] = value;
  });
  url.pathname.split("/").forEach((p) => {
    if (p !== "") {
      ret.pathParts.push(p);
    }
  });
  if (
    ret.pathParts.length !== 3 ||
    ret.pathParts[0] !== "guest" ||
    ret.pathParts[1] !== "s" ||
    !ret.pathParts[2]
  ) {
    ret.valid = false;
  } else if (
    !ret.params.ap ||
    !ret.params.id ||
    !ret.params.t ||
    !ret.params.url ||
    !ret.params.ssid
  ) {
    ret.valid = false;
  } else {
    ret.valid = true;
  }
  return ret;
}

export default class GuestAgoelzerPage extends Component {
  state = {
    page: 0,
    errors: {},
    urlParts: getUrlParts(),
    clientIp: "",
    cents: 0,
    connecting: false,
  };

  async componentDidMount() {
    await this.refreshCoins();
  }

  async refreshCoins() {
    try {
      let resCents = await axios.get(
        "https://owen.agolzer.com/api/coins.php?id=default"
      );
      this.setState({ cents: resCents.data.cents });
    } catch (e) {
      console.log(e);
    }
  }

  async getInternetAccess() {
    this.setState({ connecting: true });
    let resCents = await axios.get(
      "https://owen.agolzer.com/api/coins.php?id=default"
    );
    if (resCents.data.cents < 100) {
      this.setState({ cents: resCents.data.cents });
      return;
    }

    let res = await axios.put(
      "https://owen.agolzer.com/api/coins.php?id=default&cents=-100&reason=Internet%20Access"
    );
    if (res.data.status === "updated") {
      this.setState({ cents: resCents.data.cents - 100 });
      let res = await axios.post("https://owen.agolzer.com/api/unifi.php", {
        action: "register",
        mac: this.state.urlParts.params.id,
        site: this.state.urlParts.pathParts[2],
        ap: this.state.urlParts.params.ap,
        minutes: 30,
      });
      if (res.data.id) {
        setTimeout(() => {
          window.location.href = "http://www.google.com/";
        }, 3000);
      } else {
        alert(JSON.stringify(res));
      }
    } else {
      alert(JSON.stringify(res.data));
    }
  }

  render() {
    if (this.state.connecting) {
      return <h1>Connecting to internet....</h1>;
    }
    return (
      <React.Fragment>
        <h2>Current points available: {this.state.cents} </h2>
        <div style={{ display: "flex" }}>
          <Coins cents={this.state.cents} key="coins" />
          <input
            type="button"
            value="refresh"
            onClick={() => this.refreshCoins()}
            style={{ width: "100px", height: "50px" }}
          />
        </div>
        <br />
        {this.state.cents >= 100 ? (
          <input
            type="button"
            value="Internet Access Available"
            style={{ width: "100%", height: "50px" }}
            onClick={() => {
              this.getInternetAccess();
            }}
          />
        ) : (
          <React.Fragment>
            <h2>Points needed for Internet access: {100 - this.state.cents}</h2>
            <Coins cents={100 - this.state.cents} key="coins" />
            <h2>What to do to get points:</h2>
            <Tasks />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
