/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";

import ImageButton from "./common/imagebutton";
import wof_image from "../resources/img/wof.jpg";
import Coin25h from "../resources/img/coins/coins-usa-25h.jpg";
import UpdateCoinsPage from "./updateCoinsPage";

export default class HomePage extends Component {
  render() {
    if (
      navigator.userAgent.indexOf("iPhone") !== -1 ||
      window.location.href.startsWith("http://localhost:3000")
    ) {
      return <UpdateCoinsPage />;
    } else {
      return (
        <div className="container">
          <div className="row">
            <div className="col">
              <ImageButton
                link="/wof/index.html"
                label="Wheel of Fortune"
                image={wof_image}
              />
              <ImageButton link="/coins" label="Coins" image={Coin25h} />
            </div>
          </div>
        </div>
      );
    }
  }
}
