/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import axios from "axios";
import Coins from "./coins";

export default class Tasks extends Component {
  state = {
    tasks: [],
    newCents: "",
    newLabel: "",
  };

  async componentDidMount() {
    await this.refresh();
  }

  async refresh() {
    let res = await axios.get("https://owen.agolzer.com/api/tasks.php");
    this.setState({ tasks: res.data, newCents: 0, newLabel: "" });
  }

  handleLabel = ({ currentTarget: input }) => {
    if (input.name === "newLabel") {
      this.setState({ newLabel: input.value });
    } else {
      let index = parseInt(input.name);
      let tasks = [...this.state.tasks];
      tasks[index] = { ...tasks[index] };
      tasks[index].label = input.value;
      this.setState({ tasks });
    }
  };

  handleCents = ({ currentTarget: input }) => {
    if (input.value !== "" && isNaN(parseInt(input.value))) {
      return;
    }
    if (input.name === "newCents") {
      if (input.value === "") {
        this.setState({ newCents: "" });
      }
      this.setState({ newCents: input.value });
    } else {
      let index = parseInt(input.name);
      let tasks = [...this.state.tasks];
      tasks[index] = { ...tasks[index] };
      tasks[index].cents = input.value === "" ? 0 : parseInt(input.value);
      this.setState({ tasks });
    }
  };

  async updateCoins(cents, reason) {
    let res = await axios.put(
      "https://owen.agolzer.com/api/coins.php?id=default&cents=" +
        cents +
        "&reason=" +
        encodeURIComponent(reason)
    );
    if (res.data.status === "updated") {
      window.location.reload();
    } else {
      alert(JSON.stringify(res.data));
    }
  }

  render() {
    if (this.props.edit) {
      return this.renderEdit();
    } else if (this.props.give) {
      return this.renderGive();
    } else {
      return this.renderRead();
    }
  }

  renderRead() {
    return (
      <table border="1">
        <thead>
          <tr>
            <td>Points</td>
            <td>Task</td>
          </tr>
        </thead>
        <tbody>
          {this.state.tasks.map((t, index) => (
            <tr>
              <td>
                <Coins cents={t.cents} />
                {String(t.cents)}
              </td>
              <td>{t.label}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderGive() {
    return this.state.tasks.map((t, index) => (
      <div>
        <input
          style={{
            width: "100vw",
            fontSize: "1em",
            margin: "5px",
            height: "50px",
          }}
          type="button"
          key={index}
          value={t.label + "(" + t.cents + ")"}
          onClick={() => this.updateCoins(t.cents, t.label)}
        />
      </div>
    ));
  }

  renderEdit() {
    return (
      <table border="1">
        <thead>
          <tr>
            <td>&nbsp;</td>
            <td>Points</td>
            <td>Task</td>
          </tr>
        </thead>
        <tbody>
          {this.state.tasks.map((t, index) => (
            <tr>
              <td>
                <input
                  type="button"
                  value="Update"
                  onClick={async () => {
                    const task = this.state.tasks[index];
                    let res = await axios.put(
                      "https://owen.agolzer.com/api/tasks.php",
                      { id: task.id, cents: task.cents, label: task.label }
                    );
                    if (res.data.error) {
                      alert(res.data);
                    } else {
                      this.refresh();
                    }
                  }}
                />{" "}
                <input
                  type="button"
                  value="Delete"
                  onClick={async () => {
                    let res = await axios.delete(
                      "https://owen.agolzer.com/api/tasks.php?id=" +
                        encodeURIComponent(this.state.tasks[index].id)
                    );
                    if (res.data.error) {
                      alert(res.data);
                    } else {
                      this.refresh();
                    }
                  }}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={t.cents === 0 ? "" : String(t.cents)}
                  name={String(index)}
                  onChange={this.handleCents}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={String(index)}
                  value={t.label}
                  onChange={this.handleLabel}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <input
                type="button"
                value="Create"
                onClick={async () => {
                  let res = await axios.post(
                    "https://owen.agolzer.com/api/tasks.php",
                    {
                      cents: parseInt(this.state.newCents),
                      label: this.state.newLabel,
                    }
                  );
                  if (res.data.error) {
                    alert(res.data);
                  } else {
                    this.refresh();
                  }
                }}
              />
            </td>
            <td>
              <input
                type="text"
                name="newCents"
                value={this.state.newCents}
                onChange={this.handleCents}
              />
            </td>
            <td>
              <input
                type="text"
                name="newLabel"
                value={this.state.newLabel}
                onChange={this.handleLabel}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
