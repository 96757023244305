/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import axios from "axios";

import Coins from "./common/coins";
import Tasks from "./common/tasks";
import History from "./common/history";
import Upset from "./common/upset";

export default class UpdateCoinsPage extends Component {
  state = { cents: 0, tab: "give_coins" };

  async componentDidMount() {
    let res = await axios.get(
      "https://owen.agolzer.com/api/coins.php?id=default"
    );
    this.setState({ cents: res.data.cents });
  }

  async updateCoins(cents, reason) {
    let res = await axios.put(
      "https://owen.agolzer.com/api/coins.php?id=default&cents=" +
        cents +
        "&reason=" +
        encodeURIComponent(reason)
    );
    if (res.data.status === "updated") {
      this.setState({ cents: this.state.cents + cents });
    } else {
      alert(JSON.stringify(res.data));
    }
  }

  async blockMac(mac) {
    let res = await axios.put("https://owen.agolzer.com/api/unifi.php", {
      action: "block",
      mac: mac,
    });
    if (res.data.error === undefined) {
      alert("TV blocked");
    } else {
      alert(JSON.stringify(res));
    }
  }

  async unblockMac(mac) {
    let res = await axios.put("https://owen.agolzer.com/api/unifi.php", {
      action: "unblock",
      mac: mac,
    });
    if (res.data.error === undefined) {
      alert("TV unblocked");
    } else {
      alert(JSON.stringify(res));
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col">
          <input
            type="button"
            value="Give Coins"
            onClick={() => this.setState({ tab: "give_coins" })}
          />
          <input
            type="button"
            value="Edit Coins"
            onClick={() => this.setState({ tab: "edit_coins" })}
          />
          <input
            type="button"
            value="History Coins"
            onClick={() => this.setState({ tab: "history_coins" })}
          />
          <input
            type="button"
            value="Upset"
            onClick={() => this.setState({ tab: "upset" })}
          />
        </div>
        {this.state.tab === "give_coins" && this.renderGiveCoins()}
        {this.state.tab === "edit_coins" && this.renderEditCoins()}
        {this.state.tab === "history_coins" && this.renderHistoryCoins()}
        {this.state.tab === "upset" && this.renderUpset()}
      </div>
    );
  }

  renderGiveCoins = () => {
    const AMAZON_FIRE_MAC = "0c:47:c9:da:87:97";
    const SAMSUNG_TV_MAC = "cc:6e:a4:f9:64:6f";
    return (
      <div className="col">
        <input
          type="button"
          value="Refresh"
          onClick={() => window.location.reload()}
        />
        <br />
        Current coins available: {this.state.cents} <br />
        <Coins key="coins" cents={this.state.cents} />
        <br />
        <Tasks give={true} />
        Add/Remove coins:
        <br />
        {[25, 10, 5, 1].map((cents) => (
          <div style={{ display: "flex" }}>
            <input
              type="button"
              value={"- " + cents}
              onClick={() => this.updateCoins(-cents, "unknown")}
              style={{ width: "100px", height: "50px" }}
            />
            <Coins key={cents} cents={cents} />
            <input
              type="button"
              value={"+ " + cents}
              onClick={() => this.updateCoins(+cents, "unknown")}
              style={{ width: "100px", height: "50px" }}
            />
            <br />
          </div>
        ))}
        <input
          type="button"
          value="Block TV"
          style={{ width: "100px", height: "50px" }}
          onClick={async () => {
            await this.blockMac(AMAZON_FIRE_MAC);
            await this.blockMac(SAMSUNG_TV_MAC);
          }}
        />
        <input
          type="button"
          value="Unblock TV"
          style={{ width: "100px", height: "50px" }}
          onClick={async () => {
            await this.unblockMac(AMAZON_FIRE_MAC);
            await this.unblockMac(SAMSUNG_TV_MAC);
          }}
        />
      </div>
    );
  };

  renderEditCoins = () => {
    return (
      <div className="row">
        <Tasks edit={true} />
      </div>
    );
  };
  renderHistoryCoins = () => {
    return (
      <div className="row">
        <History />
      </div>
    );
  };
  renderUpset = () => {
    return (
      <div className="row">
        <Upset />
      </div>
    );
  };
}
