/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import axios from "axios";

export default class Upset extends Component {
  state = {
    upset: [],
    level: "",
  };

  async componentDidMount() {
    await this.refresh();
  }

  async refresh() {
    let res = await axios.get("https://owen.agolzer.com/api/upset.php");
    this.setState({ upset: res.data });
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          Level:{" "}
          <select
            name="level"
            value={this.state.level}
            onChange={({ currentTarget: input }) => {
              this.setState({ level: input.value });
            }}
          >
            <option id="">--- Select ---</option>
            <option id="low">Low</option>
            <option id="medium">Medium</option>
            <option id="high">High</option>
          </select>
        </div>
        <div className="row">
          Cause:{" "}
          <input
            type="text"
            value={this.state.cause}
            onChange={({ currentTarget: input }) =>
              this.setState({ cause: input.value })
            }
          />
        </div>
        <div className="row">
          Result:{" "}
          <input
            type="text"
            value={this.state.result}
            onChange={({ currentTarget: input }) =>
              this.setState({ result: input.value })
            }
          />
        </div>
        <div className="row">
          <input
            type="button"
            value="Create"
            onClick={async (e) => {
              e.preventDefault();
              if (
                !this.state.level ||
                !this.state.cause ||
                !this.state.result
              ) {
                alert("fill in all fields");
                return;
              }
              let res = await axios.post(
                "https://owen.agolzer.com/api/upset.php",
                {
                  level: this.state.level,
                  cause: this.state.cause,
                  result: this.state.result,
                }
              );
              if (res.data.error) {
                alert(JSON.stringify(res.data));
              } else {
                this.setState({ level: "", cause: "", result: "" });
              }
              await this.refresh();
            }}
          />
        </div>
        <div className="row">
          <table border="1">
            <thead>
              <tr>
                <td>Created</td>
                <td>Level</td>
                <td>Cause</td>
                <td>Result</td>
              </tr>
            </thead>
            <tbody>
              {this.state.upset.map((h, index) => (
                <tr>
                  <td>{h.created}</td>
                  <td>{h.level}</td>
                  <td>{h.cause}</td>
                  <td>{h.result}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}
