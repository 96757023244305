import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./components/homePage";
import CoinsPage from "./components/coinsPage";
import GuestPage from "./components/guestPage";
import UpdateCoinsPage from "./components/updateCoinsPage";

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <main className="container">
          <BrowserRouter>
            <Routes>
              <Route
                path={`${process.env.PUBLIC_URL}/coins`}
                element={<CoinsPage />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/updatecoins`}
                element={<UpdateCoinsPage />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/guest/s/:id`}
                element={<GuestPage />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/`}
                element={<HomePage />}
              />
            </Routes>
          </BrowserRouter>
        </main>
      </React.Fragment>
    );
  }
}
