/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Coins from "./common/coins";

export default class HomePage extends Component {
  state = {
    value: "0",
  };

  render() {
    return (
      <React.Fragment>
        <h1>Coins Page</h1>
        <input
          type="text"
          value={this.state.value}
          onChange={({ currentTarget: input }) => {
            if (input.value === "") {
              this.setState({ value: "" });
              return;
            }
            if (isNaN(parseInt(input.value))) {
              return;
            }
            let value = parseInt(input.value);
            if (value > 10000) {
              return;
            }
            this.setState({ value: input.value });
          }}
        />
        <hr />
        <Coins cents={parseInt(this.state.value)} />
      </React.Fragment>
    );
  }
}
